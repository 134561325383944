import type { FabricValue, SeasonValue, SexValue, SizeValue, WearCategoryValue } from "~/const/product"
import type { ProductAllListProps_Filter } from "~/types/product"

type ProductComponentFilter = ProductAllListProps_Filter & {
  sex: SexValue[]
  wear_category: WearCategoryValue[]
  fabric: FabricValue[]
  season: SeasonValue[]
  size: SizeValue[]
}

/**
 * 商品検索ページで利用する検索用処理群
 */
export const useProductsPage = () => {
  /**
   * useRoute().query.keywords から、検索用テキストボックスに表示するキーワードを作成
   * @param route useRoute した変数
   */
  const buildKeywordFromRouteQuery = (route: ReturnType<typeof useRoute>) => {
    const keywords = route.query.keywords?.toString() ?? ""

    // "aaa,bbb,ccc" -> "aaa bbb ccc"
    return keywords.split(",").join(" ")
  }

  /**
   * /products のクエリパラメーターを作成＆遷移する
   * @param keyword 検索キーワード -> PublicProductAllSearchQueryVariables の keywords に分解する
   */
  const navigateToProductsPage = async (keyword: string) => {
    const queryKeywords = keyword
      // eslint-disable-next-line no-irregular-whitespace
      .split(/ |　/)
      .filter((keyword) => keyword !== "")
      .join(",")
    await navigateTo({
      path: "/products",
      query: {
        keywords: queryKeywords,
      },
    })
  }

  const buildQueryArray = <T>(value: T | T[] | undefined): T[] => {
    if (value === undefined) return []
    return Array.isArray(value) ? value : [value]
  }
  /**
   * 商品検索ページで利用する、フィルター系コンポーネント内のフィルターを作成
   * @param query 商品検索ページのクエリパラメーター
   */
  const buildFilterFromQuery = (query: ProductAllListProps_Filter): ProductComponentFilter => {
    return {
      ...query,
      ...{
        sex: buildQueryArray(query.sex),
        wear_category: buildQueryArray(query.wear_category),
        fabric: buildQueryArray(query.fabric),
        season: buildQueryArray(query.season),
        size: buildQueryArray(query.size),
      },
    }
  }

  /**
   * 商品一覧の表示件数
   * - PC(3カラム)/SP(2カラム) で表示見切れない用に件数設定
   */
  const PRODUCT_LIST_LIMIT = "30"

  return { buildKeywordFromRouteQuery, navigateToProductsPage, buildFilterFromQuery, PRODUCT_LIST_LIMIT }
}
